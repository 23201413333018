import styles from '@styles/preloader.module.css';
import Head from 'next/head';
export default function Preloader(props) {
    return (
        <>
            <Head>
                <meta name="keywords" content="metaverse, unreal engine 5, 3d, digital twin" />
                <title>CA9.io Metaverse</title>
                <meta
                    name="description"
                    content="CA9 (Connected Authoring 9latform) is our Metaverse solution for your 3D project. Games, Digital Twins, Plannings, Architecture, Virtual Events, ... No Code, No Experience required"
                />

                <meta name="twitter:url" content="https://ca9.io" />
                <meta name="twitter:title" content="Your 3D Metaverse Solution. For Beginners and Experts." />
                <meta
                    name="twitter:description"
                    content="CA9 (Connected Authoring 9latform) is our Metaverse solution for your 3D project. Games, Digital Twins, Plannings, Architecture, Virtual Events, ... No Code, No Experience required"
                />
                <meta name="twitter:image" content="/OG/OGMain.png" />

                <meta property="og:title" content="Your 3D Metaverse Solution. For Beginners and Experts." />
                <meta
                    property="og:description"
                    content="CA9 (Connected Authoring 9latform) is our Metaverse solution for your 3D project. Games, Digital Twins, Plannings, Architecture, Virtual Events, ... No Code, No Experience required"
                />
                <meta property="og:site_name" content="CA9.io Metaverse" />
                <meta property="og:url" content="https://ca9.io" />
                <meta property="og:image" content="/OG/OGMain.png" />
            </Head>
            <div className={styles.preloadWrapper}>
                <video className={`${styles.video}`} muted={true} loop={true} id="myVideo" autoPlay={true}>
                    <source src="https://cdn.ca9.io/videos/LogoAnimation-transcode.mp4" type="video/mp4" />
                </video>
            </div>
        </>
    );
}
